import React from "react"
import Helmet from "react-helmet"

export default React.memo(({ siteUrl, title }) => {
  const schema = [
    {
      "@context": "http://www.schema.org",
      "@type": "Restaurant",
      name: "Foragers Canteen",
      telephone: "828-631-4114",
      url: "https://www.foragerscanteen.com/",
      logo:
        "https://foragers-canteen.s3.amazonaws.com/logos/foragers-canteen-logo-square.png",
      image:
        "https://foragers-canteen.s3.amazonaws.com/foragers-canteen-ext-train.jpeg",
      description:
        "Founded in 2018, Foragers Canteen brings fresh, organic, and locally sourced farm to table fare to Dillsboro, NC. Guests enjoy southern food, steaks, pasta, tacos, sandwiches, local beer, wine, and cocktails.",
      address: {
        "@type": "PostalAddress",
        streetAddress: "42 Depot Street",
        addressLocality: "Dillsboro",
        addressRegion: "NC",
        postalCode: "28725",
        addressCountry: "United States",
      },
      geo: {
        "@type": "GeoCoordinates",
        latitude: "35.3690314",
        longitude: "-83.2502121",
      },
      hasMap: "https://goo.gl/maps/yQXx3xecmtT2",
      openingHours:
        "Mo, Tu, We, Th 08:00-20:30 Fr, Sa 08:00-22:00 Su 10:00-20:00",
      priceRange: "$$",
      paymentAccepted: ["Cash", "Credit Card"],
      servesCuisine: ["Southern", "Italian", "Steaks", "Tacos"],
      hasMenu: "https://www.foragerscanteen.com/menu/",
      acceptsReservations: "No",
      contactPoint: {
        "@type": "ContactPoint",
        telephone: "+1-828-631-4114",
        contactType: "Customer Service",
      },
    },
  ]
  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
})
